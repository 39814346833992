<template>
  <div>
    <b-modal
      id="modal-users-tag-edit"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      ok-title="Save"
      ok-variant="primary"
      modal-class="modal-primary"
      centered
      :title="$t('Tag type edit')"
      @ok="confirmUpdateUsersTag"
      @hidden="resetModal()"
    >
      <b-overlay :show="show" variant="transparent" no-wrap />
      <!-- form -->
      <validation-observer ref="updateUsersTag">
        <b-form
          ref=""
          method="POST"
          class="auth-reset-password-form mt-2 repeater-form"
          :style="{ height: trHeight }"
          @submit.prevent="validationFormUsersTag"
        >
          <!-- Field: Tag Type -->
          <b-form-group label-for="type-name">
            <label class="mb-1"
              ><span class="text-danger">*</span> {{ $t("Type name") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="typeName"
              rules="required"
            >
              <b-form-input
                id="type-name"
                v-model="tagTypeData.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="errors.length > 0 ? 'is-invalid' : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Field: Description -->
          <b-form-group label-for="description">
            <label class="mb-1">{{ $t("Description") }}</label>
            <b-form-input
              id="description"
              v-model="tagTypeData.description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </b-form-group>
          <b-form-group label-for="Tags">
            <label class="h5 mb-1 border-bottom d-block pb-1">{{
              $t("Tags")
            }}</label>
            <b-row
              v-for="(tag, index) in tagTypeData.tags"
              :key="tag.id"
              ref="tagsRow"
              :class="`mb-1 border-bottom ${
                !$can('edit', 'tag') ? 'pointer-events-none' : ''
              }`"
            >
              <b-col>
                <!-- Field: Name -->
                <b-form-group label-for="tagsName">
                  <label class="mb-1"
                    ><span class="text-danger">*</span> {{ $t("Name") }}</label
                  >

                  <validation-provider
                    #default="{ errors }"
                    name="tagsName"
                    rules="required"
                  >
                    <b-form-input
                      id="tagsName"
                      v-model="tag.name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="5">
                <!-- Field: Description -->
                <b-form-group label-for="tagDescription">
                  <label class="mb-1"
                    ><span class="text-danger">*</span>
                    {{ $t("Description") }}</label
                  >
                  <validation-provider
                    #default="{ errors }"
                    name="tagDescription"
                    rules="required"
                  >
                    <b-form-input
                      id="tagDescription"
                      v-model="tag.description"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3" class="d-flex justify-content-around">
                <!-- Field: Description -->
                <b-form-group label-for="color">
                  <label class="mb-1">{{ $t("Color") }}</label>
                  <div>
                    <color-picker
                      v-model="tag.color"
                      :position="{ left: 0, top: '40px' }"
                      @change="change"
                      @afterChange="afterChange"
                    />
                  </div>
                  <b-form-input
                    id="color"
                    v-model="tag.color"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    hidden
                  />
                </b-form-group>
                <div class="align-items-center d-flex justify-content-end">
                  <b-link
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    class="text-danger"
                    variant="outline-danger"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="MinusCircleIcon" />
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-row v-if="$can('create', 'tag')" class="mb-1">
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="outline-primary"
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" /> {{ $t("Add tag") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BOverlay,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
// import colorPicker from '@caohenghu/vue-colorpicker'
import useUsersTag from "./useUsersTag";

export default {
  components: {
    BOverlay,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    tagId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      tagTypeData: {
        tagTypeId: null,
        name: "",
        description: "",
        tagIds: [],
      },
      nextTagId: 2,
      color: "#3CC2BB",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: true,
      show: false,
    };
  },
  watch: {
    async tagId(newVal) {
      if (newVal) {
        this.show = true;
        await this.getTagTypeData(newVal);
        this.show = false;
      }
    },
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    async getTagTypeData(id) {
      await this.$store
        .dispatch("app-user-tag/fetchTagTypeDetail", id)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          const data = response.data[0];
          this.tagTypeData = {
            tagTypeId: data.id,
            name: data.name,
            description: data.description,
            tags: data.tags,
            tagIds: data.tags.map((item) => item.id),
          };
        });
    },
    repeateAgain() {
      this.nextTagId = this.tagTypeData.tags.length + 1;
      this.tagTypeData.tags.push({
        id: (this.nextTagId += this.nextTagId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.tagsRow[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.tagTypeData.tags.splice(index, 1);
      this.tagTypeData.tagIds.splice(index, 1);
      this.trTrimHeight(this.$refs.tagsRow[0].offsetHeight);
    },
    async addTag(tagData) {
      await this.$store
        .dispatch("app-user-tag/addTag", tagData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === "00") {
              const tagId = response.data.data.id;
              this.tagTypeData.tagIds.push(tagId);
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    async editTag(tagData) {
      await this.$store
        .dispatch("app-user-tag/editTag", tagData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code !== "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    async beforeSendTagType(tags) {
      await tags.map((item) => {
        if (typeof item.created_at === "undefined") {
          this.addTag(item);
        } else {
          const tdata = {
            tagId: item.id,
            name: item.name,
            description: item.description,
            color: item.color,
          };
          this.editTag(tdata);
        }
      });
    },
    editTagType() {
      store
        .dispatch("app-user-tag/editTagType", this.tagTypeData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$nextTick(() => {
                this.$bvModal.hide("modal-users-tag-edit");
                this.resetModal();
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    validationFormUsersTag() {
      this.$refs.updateUsersTag.validate().then((success) => {
        if (success) {
          if (this.tagTypeData.tags) {
            this.show = true;
            this.beforeSendTagType(this.tagTypeData.tags);
            setTimeout(() => {
              this.editTagType();
            }, "2000");
            // this.show = false
          }
        }
      });
    },
    resetModal() {
      this.$emit("update:tagId", 0);
    },
    confirmUpdateUsersTag(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationFormUsersTag();
    },
    change(color) {
      console.log(color);
    },
    afterChange(color) {
      console.log(color);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.updateUsersTag.scrollHeight);
      });
    },
  },
  setup() {
    const { usersTags } = useUsersTag();

    return {
      usersTags,
    };
  },
};
</script>

<style lang="scss">
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.one-colorpicker .one-colorpanel {
  top: unset !important;
  left: unset !important;
  position: fixed !important;
}
</style>
